import React from "react"

import WOW from 'wowjs';
import { Helmet } from "react-helmet"
import { navigate } from "gatsby"
import { findDOMNode } from "react-dom";
import * as yup from 'yup';

import Topbar from '../components/topbar'
import SelectableList from '../components/selectableList'
import Switcher from '../components/switcher'
import CoverPicker from '../components/coverPicker'
import { APP_URI } from '../configs';
import { Dropdown, Checkbox } from 'semantic-ui-react'

import ImageUploading from 'react-images-uploading';

import ilustration from '../images/ilustration.jpg'
import phone from '../images/device-phone.svg'
import desktop from '../images/device-desktop.svg'
import console from '../images/device-console.svg'

import selectImage from '../images/select-image.svg'

import {register, register2, userExist} from '../userHelper.js'
import UserContext from "../userContext.js"


import '../scss/signup.scss'
import 'semantic-ui-css/semantic.min.css'

export default class signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      signUpTitle: 'Creá tu cuenta',
      buttonText: 'Siguiente',
      step: 1,
      day: null,
      month: null,
      year: null,

      errors:[],

      username: null,
      email: null,
      password: null,

      cover: 'bg bg-1'

    };
    this.references = {
      hero: React.createRef(),
      services: React.createRef(),
      numbers: React.createRef(),
      features: React.createRef(),
      logos: React.createRef(),
      contact: React.createRef(),
    }
  }


  //STEPPER

  handleNext = async(user) => {


    switch(this.state.step){
      case(1):{
        var errors = []
        var email = this.state.email;
        if(!(email != null && email.match(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/))){
          errors.push('email')
        }
        var username = this.state.username;
        if(!(username != null && username.length > 3 && username.length < 25)){
          errors.push('username')
        }
        var password = this.state.password;
        if(!(password != null && password.length > 3 && password.length < 35 && !password.includes(' '))){
          errors.push('password')
        }  
        if(errors.length === 0){
          await userExist(this.state.email).then((exists) => {
            if(!exists){
              var newUser = {
                ...user.userObject,
                'email': this.state.email,
                'username': this.state.username,
              };
              user.updateUser(newUser);
              this.setState({errors: [], step: (this.state.step + 1)})
            } else {
              alert('usuario ya existe')
              this.setState({errors: ['username']})
            }
          });
        } else {
          this.setState({errors: errors})
        }
        break;
      }
      case(2):{
        this.setState({errors: [], step: (this.state.step + 1)})
        break;
      }
      case(3):{
        this.setState({errors: [], step: (this.state.step + 1)})
        break;
      }
      case(4):{
        this.setState({errors: [], step: (this.state.step + 1)})
        break;
      }
      case(5):{
        register2(user.userObject.email, this.state.username, this.state.password, this.state.images[0]?.dataURL);
        var newUser = {
          ...user.userObject,
          'picture': this.state.images[0]?.dataURL || '/default.png',
        };
        user.updateUser(newUser);
        
        navigate('/feed?onboarding')
      }

    }
  }

  handleBack = (e) => {
    if(this.state.step > 1){
      this.setState({step: (this.state.step - 1)})
    } else{
      navigate("/")
    }
  }

  handleCoverChange = (cover) => {
    this.setState({cover})
  }

  //image
  handleImageUpload = (imageList, addUpdateIndex) => {
      this.setState({
        images: imageList
      })
  }

  // DATE PICKER

  days = () => {
    let days = [];

    for (var i = 1; i <= 31; i++) {
      days.push({key: i, text: i.toString(), value: i});
   }
   return days;
  }

  years = () => {
    let days = [];

    for (var i = 1921; i <= 2021; i++) {
      days.push({key: i, text: i.toString(), value: i});
   }
   return days;
  }

  available_months = () => {

    return [      
    {key: '1', value:'1', text:"Enero"},
    {key: '2', value:'2', text:'Febrero'},
    {key: '3', value:'3', text:'Marzo'},
    {key: '4', value:'4', text:'Abril'},
    {key: '5', value:'5', text:'Mayo'},
    {key: '6', value:'6', text:'Junio'},
    {key: '7', value:'7', text:'Julio'},
    {key: '8', value:'8', text:'Agosto'},
    {key: '9', value:'9', text:'Septiembre'},
    {key: '10', value:'10', text:'Octubre'},
    {key: '11', value:'11', text:'Noviembre'},
    {key: '12', value:'12', text:'Diciembre'}]
  }

  handleDayChange = (e, { value }) => {
    this.setState({day: value})
  }

  handleMonthChange = (e, { value }) => {
    this.setState({month: value})
  }

  handleYearChange = (e, { value }) => {
    this.setState({year: value})
  }

  render(){
    return(
    <div  className="signup" >
      <Helmet>

      </Helmet>


      <UserContext.Consumer>
      {userObject => (
       <section ref={this.references.features} className="signup--section">
         <div className="wrapper">
            <div className="signup--box">
              <div className="back--container">
                <p onClick={this.handleBack}>Volver atras</p>
                <div className="stepper">
                  <div className={this.state.step == 1 ? "step--circle active" : "step--circle"}>1</div>
                  <div className="step--line"/>
                  <div className={this.state.step == 2 ? "step--circle active" : "step--circle"}>2</div>
                  <div className="step--line"/>
                  <div className={this.state.step == 3 ? "step--circle active" : "step--circle"}>3</div>
                  <div className="step--line"/>
                  <div className={this.state.step == 4 ? "step--circle active" : "step--circle"}>4</div>
                  <div className="step--line"/>
                  <div className={this.state.step == 5 ? "step--circle active" : "step--circle"}>5</div>
                </div>
              </div>
              {this.state.step === 1 &&
                <div className="step step-1">
                  <h2 className="signup--title">Creá tu cuenta</h2>
                  <p className="signup--subtitle">Ingresá tus datos para unirte a TROOP. </p>
                  <p className="input--title">Correo electrónico *</p>
                  <div className={`ui input ${this.state.errors.includes('email') ? 'error' : ''}`}><input type="email" value={this.state.email} onChange={(e) => this.setState({email: e.target.value})} placeholder=""/></div>
                  {this.state.errors.includes('email') && <div className="error--message"><p>Entrada invalida</p></div>}
                  <p className="input--title">Nombre de usuario *</p>
                  <div className={`ui input ${this.state.errors.includes('username') ? 'error' : ''}`}><input value={this.state.username} onChange={(e) => this.setState({username: e.target.value})} type="text" placeholder=""/></div>
                  {this.state.errors.includes('username') && <div className="error--message"><p>Entrada invalida</p></div>}
                  <p className="input--title">Contraseña *</p>
                  <div className={`ui input ${this.state.errors.includes('password') ? 'error' : ''}`}><input type="password" value={this.state.password} onChange={(e) => this.setState({password: e.target.value})} placeholder=""/></div>
                  {this.state.errors.includes('password') && <div className="error--message"><p>Entrada invalida</p></div>}
                  <p className="input--title">Fecha de nacimiento</p>
                  <div className="row date">
                    <div className="dropdown--container">
                      <Dropdown key="day" placeholder="Día" fluid autocomplete='false' value={this.state.day} onChange={this.handleDayChange} selection options={this.days()} />
                    </div>
                    <div className="dropdown--container">
                      <Dropdown  key="month" placeholder="Mes" fluid autocomplete='false' value={this.state.month} onChange={this.handleMonthChange} selection options={this.available_months()} />
                    </div>
                    <div className="dropdown--container">
                      <Dropdown key="year" placeholder="Año" fluid autocomplete='false' value={this.state.year} onChange={this.handleYearChange} selection options={this.years()} />
                    </div>
                  </div>
                  <div className="terms--container">
                    <Checkbox />
                    <p>He leído y acepto todos los <span className="bold">Términos y Condiciones </span> de TROOP.</p>
                  </div>
                </div>
              }
              {this.state.step === 2 &&
                <div className="step step-1">
                  <h2 className="signup--title">Bienvenido a Troop</h2>
                  <p className="signup--subtitle">¡Hora de armar tu perfil!
Seguí los pasos a continuación. </p>
                  <p className="input--title"><span className='bold'>Seleccioná</span> los videojuegos que más juegues.</p>
                  <div className="games--selectable--container">
                  <SelectableList selectablesList={['CSGO', 'Valorant', 'GTA V', 'FIFA 21', 'CSGO', 'Valorant', 'CSGO', 'Valorant', 'GTA V', 'FIFA 21', 'CSGO', 'Valorant', 'GTA V', 'FIFA 21']} />
                  </div> 
                  <p className="input--title">Si tu juego no se encuentra en la lista, podés escribirlo acá.</p>
                  <div className="ui input"><input type="text" placeholder=""/></div>
                </div>
              }
              {this.state.step === 3 &&
                <div className="step step-1">
                  <h2 className="signup--title">Muy bien, continuemos</h2>
                  <p className="signup--subtitle">¡Buena selección de juegos! Ahora nos
gustaría saber un poco más de vos</p>
                  <p className="input--title">¿En qué <span className="bold">dispositivos</span> jugás? (Podés elegir más de uno).</p>
                  <div className="console--select">
                    <div className="console--group">
                      <img className="console--image" src={desktop}/>
                      <Checkbox label='Consola' />
                    </div>
                    <div className="console--group">
                      <img className="console--image" src={console}/>
                      <Checkbox label='Consola' />
                    </div>
                    <div className="console--group">
                      <img className="console--image" src={phone}/>
                      <Checkbox label='Consola' />
                    </div>
                  </div>
                </div>
              }
              {this.state.step === 4 &&
                <div className="step step-1">
                <h2 className="signup--title">¡Ya falta poco!</h2>
                  <p className="signup--subtitle">Sabemos que los videojuegos son parte de
tu vida y queremos saber cuánto les dedicás.</p>
                  <p className="input--title">¿Cuántos <span className='bold'> días a la semana </span> jugás?</p>
                  
                  <Switcher options={['1 a 3 días', '3 a 5 días', '5 a 7 días']}/>
                </div>
              }
              {this.state.step === 5 &&
                              <div className="step step-1">
                                <div className="profile--look--and--feel">
                                <ImageUploading value={this.state.images} onChange={this.handleImageUpload}>
                                {({ imageList, onImageUpload, onImageRemoveAll }) => (
                                  <div>
                                  <button className="profile--picture--uploader" onClick={onImageUpload}>
                                    {this.state.images?.length > 0 &&
                                      <img src={this.state.images[0].dataURL}/>
                                    }
                                    {this.state.images?.length == 0 &&
                                      <img className="select--image" src={selectImage}/>
                                    }

                                  </button>

                                  </div>
                                  
                                )}
                                </ImageUploading>
                                <div className="cover--image">
                                  <CoverPicker value={this.state.cover} onChange={this.handleCoverChange}/>
                                </div>
                                </div>
                                <p className="input--title">Si tu juego no se encuentra en la lista, podés escribirlo acá.</p>
                                <div className="ui input"><input type="text" placeholder=""/></div>
                            </div>
              }
              <button className="button--global" onClick={() => this.handleNext(userObject)}>{this.state.buttonText}</button>
            </div>
          </div>
      </section>
      )}
      </UserContext.Consumer>
    </div>
    );
  }
}
